/* You can add global styles to this file, and also import other style files */
// @use  '~se-fe-styles/styles/colors.variables';
@use '~se-fe-styles/styles/colors.classes';
@use '~se-fe-styles/styles/typography.classes';

@import './reset';

.se-fe-page__content {
  width: 100%;
}

.app-hr {
  height: 0;
  border-top: 1px solid var(--se-divider);
}
