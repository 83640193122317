*,
*::before,
*::after {
  border: 0;
  box-sizing: inherit;
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  padding: 0;
  text-decoration: inherit;
}

html {
  box-sizing: border-box;
  height: 100%;
}

body {
  min-height: 100%;
}

img {
  max-width: 100%;
}

ol,
ul {
  list-style: none;
}
